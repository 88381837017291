/* Fulfillment.css */

/* Styles for the search container */
.search-container {
    position: relative;
    /* display: inline-block; */
  }
  
  /* Styles for the search input */
  .search-input {
    width: 100%;
    padding: 10px; /* Adjust padding as needed */
    padding-right: 40px; /* Space for the search icon */
    border-radius: 20px; /* Adjust border radius as needed */
    border: 1px solid #ccc; /* Adjust border styles as needed */
    font-size: 14px; /* Adjust font size as needed */
  }
  
  /* Styles for the search icon */
  .search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
  
  /* Styles for the buttons */
  .button {
    padding: 10px 20px; /* Adjust padding as needed */
    border-radius: 20px; /* Adjust border radius as needed */
    border: 1px solid #007bff; /* Adjust border styles as needed */
    background-color: #007bff; /* Adjust background color as needed */
    color: #fff; /* Adjust text color as needed */
    font-size: 14px; /* Adjust font size as needed */
    cursor: pointer;
    margin-right: 10px; /* Adjust margin as needed */
  }
  
  /* Hover effect for the buttons */
  .button:hover {
    background-color: #0056b3; /* Adjust hover background color as needed */
    border-color: #0056b3; /* Adjust hover border color as needed */
  }
  
  /* Additional styles for the "Bulk Upload" button */
  .bulk-upload-button {
    background-color: #28a745; 
    border-color: #28a745;
    width: 100%; 
  }
  
  .bulk-upload-button:hover {
    background-color: #218838; 
    border-color: #218838; 
  }
  
  /* Styles for the fulfillment container */
  /* .fulfillment-container {
    max-width: 100%;
    overflow-x: auto;
  } */
  

  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  /* Styles for table header and cells */
  th,
  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  /* Media query for smaller screens */
  @media screen and (max-width: 600px) {
    th,
    td {
      padding: 6px;
    }
  }
  .fulfillment-container .btn-secondary {
    width: 100%;
    font-size: 14px;
    height: 47px;
    /* position: relative; */
    padding: 10px 0px;
}
  .fulfillment-container table{
    border:none !important;
  }
  /* CSS */
.borderless-table {
  border-collapse: collapse;
  width: 100%;
}

.borderless-table th,
.borderless-table td {
  border: none;
  padding: 8px; /* Adjust padding as needed */
}

.even-row {
  background-color: #fff !important;
}

/* You can define other styles for odd rows if needed */
.odd-row {
  background-color: #f5f5f5 !important; /* Change to whatever color you prefer for odd rows */
}

/* Example hover effect for rows */
tr:hover {
  background-color: #e0e0e0; /* Change to the desired hover color */
}
.warehouselisting--td{
  cursor: pointer;
}
.fulfillment--btn {
  background: #fff;
  border: 1px solid #4D5972;
  width: 150px;
  white-space: nowrap;
  margin-top: 4px;
  padding: 7px 0px;
  color: #000;
  border-radius: 1px;
}
.fulfillment--bulkupload {
  background: #fff;
  border: 1px solid #4D5972;
  width: 162px !important;
  white-space: nowrap;
  margin-top: 4px;
  font-size: 14px;
  padding: 10px 0px;
  height: 47px;
  width: 228%;
}
/* .shipment-methods--table tbody tr:nth-child(odd) {
  background-color: #f2f2f2 !important; 
}  */
.borderless-table{
  table-layout: fixed;
}
.action--td{
  width:6%;
}
