.add-shipment-configbtn{
    background: #fff;
    border: 1px solid #4D5972;
  
    white-space: nowrap;
    margin-top: 5px !important;
    font-size: 14px;
    padding: 10px 4px;
    height: 50px;
}
.shipmentconfiguration--list .shipment-template{
    background: none;
}
.tabl-scrol{
    max-height: 350px;
    overflow-y: scroll;
}
.shipping-configuartion-detailIcon{
    cursor:pointer;
}
.action--w{
    width:10%;
}
.add-shipment-configbtn{
    margin-top:-3px;
}