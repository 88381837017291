.input--search{
    border: 1px solid #D8D8D8
}
.search-container {
    display: flex;
    margin-top: 3px;
    align-items: center;
    border:1px solid #d5d5d5;
    background: #4D5972;
    /* padding: 8px; */
    border-radius: 4px;
  }
  .fulfillment-search-input {
    height: 48px;
    width: 87%;
    font-size: 14px !important;
    margin-top: 0px !important;
    border-radius: 1px 0px 0px 1px;
}
  .fulfillment-search--icon {
    position: relative;
    left: 15px;
    cursor: pointer;
    height: 48px;
    color: #fff;
    font-size: 25px;
    margin-top: 0px !important;
    outline: none;
    padding: 0px !important;
    position: relative;
    top: 2px;
}
 