.custom-tabs {
  border-radius: 5px;
  overflow: hidden;
  margin-top: 1.5%;
}
  
  .ant-picker-input>input::placeholder {
    color: #000000 !important;
 }
 
 