.popup--title {
    font-size: 14px;
    font-weight: 900;
  }
  .btn-close {
    float: right;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity = 20);
    opacity: 1;
  }
  .uploadfiles--btn {
      font-size: 14px;
      height: 48px;
      width: 100%;
      font-weight: 600;
      top: 67px;
      background: #fff;
      border: 1px solid #4D5972;
      /* opacity: 0px; */
      font-weight: 900;
  }
  .downloadfiles--btn {
    height: 48px;
    top: 67px;
    width: 100%;
    font-size: 14px;
    color: #fff;
  background: #4D5972;
    border: 1px solid #4D5972;
    /* opacity: 0px; */
    font-weight: 900;
  }
  .fil--icon{
      position: relative;
      left: 11px;
  
  }
  .upload--btn{
    height: 48px;
    top: 67px;
    width: 100%;
    font-size: 14px;
    color: #fff;
    background: #4D5972;
    border: 1px solid #4D5972;
    /* opacity: 0px; */
    font-weight: 900;
    border-radius: 1px;
    width: 30%;
  }
  .fileselect--input{
    border-radius: 0px;
      height: 45px;
      font-size: 14px;
      border: 1px solid #4D5972;
  
  }
  .browsefiless-btn {
    background: #4D5972;
    border: 1px solid #4D5972;
    border-radius: 1px;
    font-size: 14px;
    height: 44px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    padding: 11px 53px;
    position: absolute !important;
    right: 14px !important;
    cursor: pointer;
  }
  .modal-content {
    border-radius: 1px;
      border: none;
  }
  .modal-header {
    border: none;
  }
  .delete-shipmentmethod {
    background: #4D5972;
    position: relative;
    right: 10px;
    padding: 4px 20px;
    font-size: 14px;
    color: #fff;
    border: none;
    font-weight: 700;
    font-family: 'AvenirLTStd-Book';
}
.delete--alert{
    font-family: 'AvenirLTStd-Book';
    font-size: 14px;
    font-weight: 900;
    line-height: 10px;
}