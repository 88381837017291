.add--serviceability{
    width: 20%;

    position: absolute;
    right: 11px;
    top:20px;
    background: #003366;
    color:#fff;
    border:none;
 
}
.add--serviceability:hover{
    background:#003366;
    color:#fff;
}
.ma-ht{
  height:47px !important;
}
a{
    color:#000;
    text-decoration: none;
}
.col-md-3 {
    /* display: flex;
    flex-direction: column; */
  }
  
.col-md-3 input[type="text"] {
    flex: 1;
    height:35px;
    border:1px solid #d5d5d5;
    border-radius:5px;
    width:-webkit-fill-available;
    outline: none;
  }
  .inpt--val{
    flex: 1;
    height:35px;
    border:1px solid #d5d5d5;
    border-radius:5px;
    width:-webkit-fill-available;
    outline: none;
  }

  .submit{
    margin-top: 25px;
    background: #003366;
    border: none;
    color: #fff;
    border-radius: 6px;
    width: 70%;
    height: 35px;
  }
  .bulk--btn{
    background: #003366;
    border: none;
    color: #fff;
    white-space: nowrap;
    height: 35px;
    border-radius: 6px;
    width:100%;
  }
  .add--shipment--btn{
    background: #003366;
    border: none;
    color: #fff;
    white-space: nowrap;
    height: 35px;
    border-radius: 6px;
    width:20%;
    margin: auto;
    margin-top:10px;

  }
  .fa-ic{
    height: 12px;
    position: relative;
    left: 7px;
    top: -1px;
  }
  
  #shipmentMethod{
    height: 35px;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
  }
.add-priority-btn{
  float: right;
    position: absolute;
    right: 8px;
    top: 17px;
    background: #003366;
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 5px;

}
.first--row select{
  margin:10px 10px 0px 10px
}
.blk-add{
  background: #003366;
    border: none;
    color: #fff;
    border-radius: 6px;
    width: 100%;
    height: 35px;
}
.submit{
    position: relative;
    left: 28%;
}
.search-input{
  margin-top:10px;
}
/* Add this CSS to your stylesheet or component style */


/* table {
  
  border-collapse: collapse;
  white-space: nowrap; 
}
.warehouselisting--td {
  max-width: 150px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px; 
  border: 1px solid #ddd; 
}

th {
  background-color: #f2f2f2; 
} */
/* .warehouselisting--td {
 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
  border: 1px solid #ddd;
}
.warehouselisting--div{
  overflow-x: scroll;max-width: 67%;margin: 10px;overflow-y: scroll;max-height: 400px;
} */
.warehouselisting--td {
 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
  /* border: 1px solid #ddd; */
}
.warehouselisting--td td{
  width:166px;
  border: none;
}
.input-class{
  height: 39px;
  width:150px;
    border-radius: 5px;
    border-color: #d5d5d5;
    box-shadow: none;
    border: 1px solid #d5d5d5;
}
.search-container {
 
  align-items: center;
  /* border:1px solid #d5d5d5; */
  /* padding: 8px; */
  border-radius: 4px;
}
button.priority-btn {
  width: 50px;
  border: none;
  background: hwb(0deg 39.22% 26.67%);
  height: 37px;
  border-radius: 7px;
  margin: 0px 2px 0px 2px;
  color: #fff;
  box-shadow: none;
}
.search-icon {
  margin-right: 8px;
  position: relative;
  left: -20px;
  top: 5px;
}

.search-input {
  border: none;
  outline: none;
}
.submit-search{
  margin-top: 25px;
  background: #003366;
  border: none;
  color: #fff;
  border-radius: 6px;
  width: 100%;
  height: 35px;
}
@media only screen and (max-width: 768px) {
  div {
    max-width: 100%;
    overflow: auto;
  }
}
.upload--files{
  width:100% !important;
}
.serviceability-crd button.btn.btn-success.mt-2 {
  white-space: nowrap;
  border-radius: 0px;
  height: 40px;
  margin: 0 !important;
}
.btn-success{
  width: 100%;
  font-size: 14px;
  border: none;
  background-color: #fff;
  border: 1px solid #4D5972;
  color: #000;
}
.serviceabilty-bulk--upload{
  white-space: nowrap;
  width: 100%;
    border-radius: 0px;
    height: 40px;
    margin: 0 !important;
    border: none;
    background-color: rgba(77, 89, 114, 1);
    color: #fff;
}
.serviceable--pincodes--listing {
  max-height: 400px;
  overflow-y: scroll;
}
button.checkbtn {
  background: transparent;
  border: none;
  color: green;
  font-size: 14px;
}
.submit-search{
  width: 100%;
  font-size: 14px;
  border: none;
  background-color: #4D5972;
}
.first--row-{
  margin-top:2px;
}
.the-pincodehdr{
  font-weight: 800 !important;
  font-family: 'AvenirLTStd-Book' !important;
}
.serviceability--inp{
  padding: 0px 13px 0px;
    font-size: 14px;
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
    border-radius: 0px !important;
    background: rgba(244, 244, 244, 1);
    border: rgba(244, 244, 244, 1) !important;
    margin-bottom: 25px;
    height: 41px !important;
}
.serviceability--headers{
  font-size: 14px;
    font-weight: 800;
    
    text-transform: capitalize;
}
.submit-search--serviceability{

  width: 100%;
    height: 41px;
    color: #fff;
    border: none;
    margin-top: 22px;
    font-size: 14px;
    background: rgba(77, 89, 114, 1);

}
.serviceability--headers {
  font-size: 14px;
  font-family: 'AvenirLTStd-Book';
  font-weight: 800;
  text-transform: capitalize;
}
.back-p,.add-fulfillment-para{
  font-family: "AvenirLTStd-Book";
}
.serviceability--master--table thead th td{
  border-bottom:none !important;
}
.serviceability--master--table tbody{
  border-bottom:none !important;
}
.serviceability--master-tableheader{
  font-family: 'AvenirLTStd-Book';
}

.serviceability--masterinput{
  border:none !important;
  outline: none;
  background: none;
}
.serviceability--master--table{
  font-family: 'AvenirLTStd-Book';
}
.serviceability--master--table input.form-control.shadow-none {
  font-size: 14px;
  border: none;
  background: transparent;
  border-bottom: 1px solid #d5d5d5;
  border-radius: 0px;
}
.action--mastertable{
  width: 6%;
}
.check-btn{
  background: none;
  border: none;
  color: green;
  font-size: 16px;
  font-weight: 600;

}