@import url('../src/fonts/fonts.css');


body {
  font-family: 'AvenirLTStd-Book', Arial, sans-serif; /* Fallback fonts */
  margin: 0;
  padding: 0;
  display:block;
  overflow-x: hidden;

}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
  background: linear-gradient(90deg, #0AC9D8 0%, #000708 100%) !important;
}
.Toastify__toast {
  border-radius:10px;
}
.Toastify__toast-body{
  font-family: 'AvenirLTStd-Book';
    font-size: 15px;
    font-weight: 500;
    line-height: 10px;
    color:#000;
}

.Toastify__close-button--light {
    color: #000;
    opacity: 1;
}
.Toastify__toast-theme--light {
    background: var(--toastify-color-light);
    color: var(--toastify-text-color-light);
    
}
table th {
  white-space: nowrap !important;
  background: #4D5972 !important;
  font-weight: 200;
  color: #fff !important;
}
.ant-tabs-nav-list{
  font-family: 'AvenirLTStd-Book', Arial, sans-serif;
  font-weight: 900;
  font-size: 14px;
}
.ant-picker .ant-picker-suffix {

  color: #0AC9D8;
}
.Toastify__toast-container--top-right {
  top: 10% !important;
  left: 50%;
  width:39%;
  transform: translate(-50%, -50%);
  /* transform: translateX(-50%); */
}
ul li .custom-link {
  text-decoration: none !important;
 
}

span.routes--li{
  padding-left: 8px !important;
    position: relative !important;
    top: 2px !important;
}

h1 {
  font-family: 'NoirPro-Regular', sans-serif; /* Example usage for heading 1 */
}
/* .app {
  display: flex;
  flex: 1;
} */

/* .sidebar {
  width: 233px;
  background: #003366;
  color: #fff;
  margin-top: 70px;
  position: fixed;
  height: 100vh;
} */

.content {
  flex: 1;
  padding: 20px;
  height: 100%;
  margin-top: 40px;
  margin-bottom: 60px;
  /* Adjusted margin for larger screens */
  /* margin-left: 20%; */
   /* Adjust the percentage based on your layout */
  /* background: #F8F8F9; */
}

/* Adjustments for larger screens */
@media (min-width: 768px) {
 
  .sidebar {
    position: relative;
    top: 70px;
    height: 67px;
    background: #E3FDFF;
}
  .sidebar ul li {
    padding: 10px;
    display: inline-flex;
}
}

.content .filters--row {
 
  background: #F8F8F9;
  margin-top: 2%;
  margin-left: 0px;
  margin-right: 0px; 
  border-radius:10px;
  padding:10px
}
.content .filters--row button {
  margin-top:5% !important;
}
.content table{
  margin-top:15px;
}
.filters--row input{
  background: #fff;
  font-size: 14px;
  border-radius: 15px;
 
}
/* .table .table-striped{
  background:#F8F8F9;
} */
.filters--row .col-md-3{
  margin-top:15px;
}
.content.expanded {
  margin-left: 250px;
}
/****Header Css Starts ***/

.brand--image{
  height:35px;
}



/****Header Css Ends ***/
.fixed-botttom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  height: 40px;
  font-size: 13px;
}
/* Rest of the existing CSS */


.sidebar.collapsed {
  width: 60px;
  margin-top: 70px;
  padding: 10px;
  display: none;
}
.sidebar.collapsed li:hover{
  background-color: #202940 !important;
  
}

.mr-right{
  position: absolute;
  right: 0;
}

.toggle-btn {
  background: none;
  border: none;
  color: #000;
  font-size: 24px;
  cursor: pointer;
}

.toggle-btn:focus {
  outline: none;
}


.sidebar ul li {
  padding: 10px;
}

.sidebar ul li a {
  color: #000;
  text-decoration: none;
}

.sidebar ul li a:hover {
  text-decoration: underline;
}

.content {
  flex: 1;
  /* padding: 20px 75px; */
  padding: 20px 56px;

  background-image: url('./images/OMS.svg');
  background-repeat: no-repeat;
  background-position: bottom right; /* Key property for positioning */
  background-size: inherit; /* Optional for image scaling */
 

}

header, footer {
  background: #f0f0f0;
  padding: 10px;
  text-align: center;
}

/* Responsive styles */

@media (max-width: 768px) {
  .app {
    flex-direction: row;
  }

  .sidebar {
    width: 80px;
  }

  .sidebar.collapsed {
    width: 80px;
  }

  .content {
    padding: 20px;
  }
}
/******Login Css*****/
.login-container {
  /* max-width: 300px; */
  padding: 20px;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 36%;
  transform: translate(-50%, -50%);
  border: none;
  box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  padding: 50px;
}
.login--input:focus{
  outline: none;
}
.login--div{
  width: 25%;
  margin: auto;
  padding:10px 40px 40px;
  border-radius:10px;
  border:'1px solid #ccc'
}
.login-container .form-group {
  margin-bottom: 10px;
}

.login-container label {
  display: block;
  margin-bottom: 5px;
}

.login-container .login--input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  border-radius: 20px;
}
.login-container .login--heading{
  text-align: center !important;
}
.login-container .login--btn {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 3px;
  background-color: #007BFF;
  color: white;
  font-size: 16px;
  cursor: pointer;
}


.login-container .login--btn{
  background: #d33f8d;
  border-radius: 20px;
  margin-top:10px
}
/*****Dashboard Page Css Starts here*****/
.icon--pos{
float:right;
}
.icon--bg--one{
    background-color: #059669;
    border-radius: 25%;
    padding: 11px;
    height: 51px;
    width: 50px;
    color:#fff;
}
.icon--bg--two{
  background-color: #DB2777;
  border-radius: 25%;
  padding: 11px;
  height: 51px;
  width: 50px;
  color:#fff;
}
.icon--bg--three{
  background-color: #D97706;
  border-radius: 25%;
  padding: 11px;
  height: 51px;
  width: 50px;
  color:#fff;
}
.icon--bg--four{
  background-color: #2563EB;
  border-radius: 25%;
  padding: 11px;
  height: 51px;
  width: 50px;
  color:#fff;
}
.icon--bg--five{
  background-color:  #DC2626;
  border-radius: 25%;
  padding: 11px;
  height: 51px;
  width: 50px;
  color:#fff;
}
.icon--bg--six{
  background-color: #6366F1;
  border-radius: 25%;
  padding: 11px;
  height: 51px;
  width: 50px;
  color:#fff;
}
.card-title.h5 {
  color: #000000;
  font-size: 15px;
  text-wrap: pretty;
  font-weight: bold;
}
.card-text:last-child {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 21px;
}
.container{
  margin-top:25px
}
/* CSS for Orders component */

.orders--row {
  margin-top: 2%;
}

.orders--row .MuiButton-contained.print-button {
  background: #FFFFFF;
  color: #2D4047;
  font-weight: bold;
  margin-top: 7px !important;
  padding: 5px 11px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.orders--row .MuiButton-contained.print-button:hover {
  background: #2D4047;
  color: #FFFFFF;
  border:1px solid #000
}

.orders--row .MuiButton-contained.print-button .MuiButton-startIcon {
  margin-right: 0;
}

.orders--row .MuiButton-contained.print-button .MuiSvgIcon-root {
  width: 1.2em;
  height: 1.2em;
}

.orders--row .MuiButton-contained.print-button .MuiButton-label {
  display: flex;
  align-items: center;
}

.orders--row .MuiOutlinedInput-root.export-select {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 10px;
  height: 39px;
  margin-top: 5px;
}

.orders--row .MuiOutlinedInput-input.export-select-input {
  padding: 10px;
}

.orders--row .MuiSelect-icon.export-select-icon {
  top: 30%;
}

.orders--row .MuiSelect-selectMenu.export-select-menu {
  height: 100%;
  display: flex;
  align-items: center;
}

.filters--row .MuiTextField-root {
  width: 100%;
  margin-bottom: 10px;
}


/*****Orders Listing page css start here***/
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
}

.full-width-element {
  width: 100vw;
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}
th {
  font-size: 14px;
  font-weight: 800 !important;
}
tbody tr:nth-child(odd) {

  color: #000;
  
}
table{
  width: 100% !important;
  border:none !important;
}
::-webkit-scrollbar {
  width: 8px; 
  height: 8px;
  border-radius: 8px; 
}

/* Style for the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #d5d5d5; 
  border-radius: 8px; 
}


::-webkit-scrollbar-track:hover {
  background-color: #9b9b9b; 
}


::-webkit-scrollbar-thumb:hover {
  background-color: #d4d4d4; 
}