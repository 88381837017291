/* .loader-container {
    margin: 0 auto;
    margin-top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  .loader-routes {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
  }
  
  /* Loader.css */
/* .loader-container {
    position: relative;
  } */
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* Adjust opacity as needed */
    z-index: 999; /* Ensure overlay is on top */
  }
  