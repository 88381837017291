/* @font-face {
    font-family: 'NoirPro-Regular';
    src: url('../fonts/NoirPro-Regular.ttf') format('truetype');
  } */
  @font-face {
    font-family: 'SF-Pro-Text-Regular';
    src: url('../fonts/SF-Pro-Text-Regular.otf') format('opentype');
   
  }
  @font-face {
    font-family: 'AvenirLTStd-Book';
    src: url('../fonts/AvenirLTStd-Book.otf') format('opentype');
   
  }
  
  