.add--serviceability{
    width: 20%;

    position: absolute;
    right: 11px;
    top:14px;
    background: #003366;
    color:#fff;
    border:none;
 
}
table tr {
    border-style: dashed !important;
    height: 54px !important;
}
.add--serviceability:hover{
    background:#003366;
    color:#fff;
}
a{
    color:#000;
    text-decoration: none;
}
.email-cell {
    max-width: 100px; /* Adjust width as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p.warehouse--headers {
   
    font-size: 14px;
    font-weight: 800;
    line-height: 10px;
    text-transform: capitalize;
   
}

.warehouse--input--value {
    padding: 0px 13px 0px;
    font-size: 14px;
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
    border-radius: 0px !important;
    background: rgba(244, 244, 244, 1);
    border: rgba(244, 244, 244, 1) !important;
    margin-bottom: 25px;
    height: 45px !important;
}
.update--warehouse--details{
    width: 100%;
    /* height: 41px; */
    padding: 12px;
    color: #fff;
    border: none;
    font-size: 14px;
    background: rgba(77, 89, 114, 1);
}
.edit--warehouse--details{
    width: 100%;
    /* height: 41px; */
    padding: 11px;
    /* color: #fff; */
    float: right;
    margin-bottom: 10px;
    border: none;
    font-size: 14px;
    border:1px solid #4D5972;
    background: #fff;
    color:#000
}
.serviceabilty-bulk--upload{
    white-space: nowrap;
    width: 100%;
      border-radius: 0px;
      height: 40px;
      margin: 0 !important;
      border: none;
      background-color: rgba(77, 89, 114, 1);
      color: #fff;
      font-size: 14px;
  }
  .warehouse--managemnt{
    padding: 10px 0px;
  }
  .lb{
    width: 100%;
    font-size: 14px;
    border: none;
    background-color: #fff;
    border: 1px solid #4D5972;
    color: #000;
    border-radius: 1px;
    margin-top: 0.5% !important;
    /* padding: 9px 5px; */
    height: 40px;
  }