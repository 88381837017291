/* Orders.css */
.filters--row{
  margin-top:30px  !important;
}
.orders--row{
  border-bottom: 4px solid #ECEEEE;
}
.styleInput{
 height:30px !important;
}
/* .table-container {
    padding: 20px;
  } */
   .yes--bt{
    background: #4d5972;
    color: #fff;
    font-family: 'AvenirLTStd-Book';
    font-size: 11px;
    border-radius: 1px;
    text-transform: capitalize;
   }
  .slt{
    border: 1px solid #d5d5d5;
    padding: 7px;
    width: 100%;
    margin-top: 10px;
  }
  .table-custom {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  .search--icon{
    height:20px;
    position: relative;
    top: 14px;
    right:8px;
    background: #fff;
    cursor: pointer;
  }
  .input--search{
    border: none !important;
    position: relative;
    margin-top:6px;
  }
  .search--container{
    border: 1px solid #D8D8D8;
    height: 48px;
    width: 100%;
    display: flex;
    border-radius:2px;
}
.input--search::placeholder {
  color: #D8D8D8;
  opacity: 1;
  font-size: 14px;
}
 .brand--dropdown{
 
    word-wrap: normal;
    border: 1px solid #D8D8D8;
    color: #000;
    font-size: 14px;
    height: 48px;
    border-radius: 2px;
    width: 100%;
    background-color: #fff;
    padding-left: 10px;
 } 
.top--spacing{
  margin-top:1%;

}
  
  .table-custom thead {
    background-color: #f8f9fa;
    color: #333;
  }
  
  .table-custom th,
  .table-custom td {
    padding: 12px 15px;
    text-align: left;
    font-size: 14px;
  }
  
  .table-custom tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table-custom tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .create-order-button{
    white-space: nowrap !important;
    background:#34DBA1 !important;
  }
  .row .orders--row{
    margin-top:3% !important;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    width:68% !important;
  }
  
  .MuiDialog-container .form-group input{
    border-radius: 10px;
    border: 1px solid #ddd;
    display: block;
    padding: 8px;
    width:100%;
  }
  .MuiDialog-container  input{
    border-radius: 10px;
    border: 1px solid #ddd;
    display: block;
    padding: 8px;
    width:100%;
  }
  .MuiDialog-container .sbmit--btn{
    background: #34DBA1 !important;
    color: #fff;
    border: none !important;
    border-radius: 6px;
    width: 37%;
    padding: 8px;
    float: right;
    margin: auto;

  }
  .MuiDialog-container table{
    margin: 2px 19px 11px 11px;
    width: 97%;
  }
  .form-group textarea{
    border-radius: 10px;
    border: 1px solid #ddd;
    display: block;
    width:100%;
  }
  .submitbtn{
    background: #34DBA1 !important;
    border: none;
    width: 40%;
    color:#fff;
    padding: 10px;
    border-radius: 10px;
  }
  .MuiPaper-root-MuiDialog-paper{
    max-width:700px !important;
  }
  .table-container{
    /* max-height:500px;
    overflow-y:scroll; */
  }
  .table-container th {
    white-space: nowrap;
    background: #4D5972 !important;
    color: #fff;
    font-family: 'AvenirLTStd-Book', Arial, sans-serif;
}
  td .eye--icon{
    cursor: pointer;
  
  }
  .table-container {
    margin-top: 17px;
    table-layout: fixed;
}
table{
  table-layout: fixed;
}
  .eye-icon-center{
    text-align: left;
  }
  .pagination--btn{
    background: #fff;
    box-shadow: 0px 0px 4px 0px #00000040;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    border:none;
  }
  .datepicker--btn{
    height: 48px;
    border-radius: 2px;
    width: 100%;
  }
  

select {
  word-wrap: normal;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #000;
}
.order--listing--downloadbtn{
  background: #fff !important;
    border: none;
    padding: 10px;
    width: 190px;
    box-shadow: 0px 0px 4px 0px #00000040;
    border-radius: 5px;
    margin-left: 1px;
}
.leftIcon{
  position: relative;
  left: -3px;
  cursor: pointer;
}
.rightIcon{
  position:relative;
  right:3px;
  cursor: pointer;
}
.orders--count--btn {
  position: absolute;
  right: 8px;
  background: #fff !important;
  border: none;
  padding: 10px;
  width: 70px;
  box-shadow: 0px 0px 4px 0px #00000040;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}
/* 
tr:nth-child(odd) {
  background-color: #F4F4F4;
  color: #fff;

} */
.dropdownIcon{
  position: absolute;
  top: 19px;
  margin-left: -26px;
  background-color: #fff !important;
  cursor: pointer;
}
div#rc-tabs-1-tab-2 {
  font-weight: 800;
}
.orderslisting--table tbody tr:nth-child(odd) {
  color: #000;
  background: #fff !important;
}
.table--wrapper-scroll {
  max-height: 400px;
    overflow-y: scroll;
}

.tablee--bottom{
  margin-top:20px;
}

.pag-div{
  margin-top: 7px;
}
.action--icon--td{
  width:6% !important;
}
button.normal {
  border-radius: 5px;
  border: none;
  width: 22px;
  margin: 5px 7px 5px 2px !important;
  padding: 0px;
  text-align: center;
  height: 22px;
  background: transparent !important;
  color: #000 !important;
}
.brand--dropdown {

  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
.circle-dropdown {
  display: flex;
  align-items: center;
}

.option {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.circlee {
  width: 30px !important;
  height: 8px !important;
  border-radius: 50%;
  margin-right: 5px;
}
.table--wrapper-scroll th{
  width:11%;
}

