.custom-link{
    white-space: nowrap;
}
.nested--menu {
    /* margin-left: 25px !important; */
    /* background: #fff; */
    position: absolute;
    top: 41px;
    font-size: 13px;
    padding-left: 6px !important;
}
.toggle-header-menu {
    position:absolute;
    /* top: 123px; */
    right: 5px;
    background: #E3FDFF;
    padding: 7px;
    color: #fff;
   
    width: 46px;
    height: 38px;
    text-align: center;
    border-radius: 0px 0px 10px 10px;
    cursor: pointer;
}
.nested--menu li a:hover {
    text-decoration: none !important;
    border-bottom: none !important;
}
.nested--menu li:hover {
    text-decoration: none !important;
    border-bottom: none !important;
}
.nested--menu li a{
    white-space: nowrap;
    text-decoration-line: none;
}
.sidebar  {
   
    box-shadow: 0px 3px 5px 0px #0000001A;
    margin:0px !important;
    transition: width 0.3s ease;
}
.sidebar--ul li:hover {
    border-bottom:  3px solid #0AC9D8 !important;
}
ul li .custom-link:hover{
    color:#000 !important;
    font-weight: bold;
  }
  
  .nested--menu li {
    display: block !important;
    margin-top: -10px;
}




.rotated {
    transform: rotate(180deg);
    margin-top: -65px;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 2px 4px 0px #00000040;

}