.table>thead {
    vertical-align: bottom;
    text-transform: capitalize;
}

.inventory--table tbody tr:nth-child(even) {
    background-color: #f2f2f2; 
  }
  .inventory--table tbody tr,td:hover {
    background-color: none !important; 
  }
  .inventory--table tbody tr,td {
border:none !important;
  }
  .inventory--table tbody th,td {
    padding: 9px 15px;
    
      }
      .inventory--table thead th {
        background: #4D5972;
        text-transform: capitalize;
        /* width: 100% !important; */
    }
    .inventory--table tbody tr td{
      overflow: hidden;
      text-overflow: ellipsis;
      width: 72px;
      white-space: nowrap;
    }
    .fulfillment--bulkupload {
      background: #fff;
      border: 1px solid #4D5972;
      width: 150px;
      white-space: nowrap;
      position: relative;
      left: 25px;
  
      margin-top: 4px;
      font-size: 14px;
      padding: 10px 0px;
      height: 47px;
      width: 228%;
  }
    .inventory--table--div{
      /* overflow-x: scroll;
    max-width: 1254px; */
    margin-top: 3%;
    }
      /* .inventory--table  th {
        background-color: #DEDBDB !important;
        color: #000 !important;
        
          }
     */
     .th-width-80 {
      width: 80px !important;
  }
  
  .th-width-60 {
      width: 60px !important;
  }
  
  .th-width-123 {
      width: 123px !important;
  }
  
  .th-width-132 {
      width: 132px !important;
  }
  .th-width-135{
    width:135px !important
  }
  