.bulk-upload-button {
    white-space: nowrap;
    background: #4D5972 !important;
    border: none;
    padding: 10px;
    width: 60%;
    margin-top: 7px;
    border-radius: 11px;
}

.shipment--method--bulkupload{
    background: #fff;
    border: 1px solid #4D5972;
    width: 100% !important;
    white-space: nowrap;
    margin-top: 9px;
    padding: 7px 0px;
    color: #000;
    border-radius: 1px;
    height:50px;

}
.table-container th {
    white-space: nowrap;
    background: #4D5972 !important;
    color: #fff;
    font-weight: 800;
    font-family: 'AvenirLTStd-Book', Arial, sans-serif !important;
}

.table-container table td {
  border:none !important;
}

.table-container .shipment-methods--table:hover {
    border:none !important;
    background: none !important;
  }
.action--td-{
  width:6%;
  
}
.fa-trsh{
  cursor: pointer;
}
 /* .shipment-methods--table tbody tr:nth-child(odd) {
  background-color: #f2f2f2 !important; 
}   */
.shipmentmethod--table-wrapper{
  max-height: 400px;
  overflow-y: scroll;
}
.shipment-method-update{
  padding-right: 8px;
}
.edit-op{
  font-size: 18px;
}
.editShipment--input{
  border-radius: 1px;
  border: 1px solid #F4F4F4;
  height: 40px;
  background: #ffffff;
  outline: none;
}