.add--serviceability{
    width: 20%;

    position: absolute;
    right: 11px;
    top:20px;
    background: #003366;
    color:#fff;
    border:none;
 
}
.add--serviceability:hover{
    background:#003366;
    color:#fff;
}
a{
    color:#000;
    text-decoration: none;
}
.col-md-3 {
    /* display: flex;
    flex-direction: column; */
  }
  
.col-md-3 input[type="text"] {
    flex: 1;
    height:35px;
    border:1px solid #d5d5d5;
    border-radius:5px;
    width:-webkit-fill-available;
    outline: none;
    padding-left: 10px !important;
  }
  .inpt--val{
    flex: 1;
    height:35px;
    border:1px solid #d5d5d5;
    border-radius:5px;
    width:-webkit-fill-available;
    outline: none;
  }

  .submit{
    margin-top: 25px;
    background: #003366;
    border: none;
    color: #fff;
    border-radius: 6px;
    width: 70%;
    height: 35px;
  }
  .bulk--btn{
    background: #003366;
    border: none;
    color: #fff;
    white-space: nowrap;
    height: 35px;
    border-radius: 6px;
    width:100%;
  }
  .tag--input{
    height: 40px;
    border: 1px solid #d5d5d5;
    border-radius: 7px 0px 0px 7px;
    font-size:14px !important;
  }
  .tag--search{
    background: #003366;
    color: #fff;
    height:40px;
    border-radius: 0px 7px 7px 0px;
    box-shadow: none;
  }
  .add--shipment--btn{
    background: #003366;
    border: none;
    color: #fff;
    white-space: nowrap;
    height: 35px;
    border-radius: 6px;
    width:20%;
    margin: auto;
    margin-top:10px;

  }
  .fa-ic{
    height: 12px;
    position: relative;
    left: 7px;
    top: -1px;
  }
  
  #shipmentMethod{
    height: 35px;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
  }
.add-priority-btn{
  float: right;
    position: absolute;
    right: 8px;
    top: 17px;
    background: #003366;
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 5px;

}
.first--row select{
  margin:10px 10px 0px 10px
}
.blk-add{
  background: #003366;
    border: none;
    color: #fff;
    border-radius: 6px;
    width: 100%;
    height: 35px;
}
.submit{
    position: relative;
    left: 28%;
}

/* Add this CSS to your stylesheet or component style */


/* table {
  
  border-collapse: collapse;
  white-space: nowrap; 
}
.warehouselisting--td {
  max-width: 150px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px; 
  border: 1px solid #ddd; 
}

th {
  background-color: #f2f2f2; 
} */
/* .warehouselisting--td {
 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
  border: 1px solid #ddd;
}
.warehouselisting--div{
  overflow-x: scroll;max-width: 67%;margin: 10px;overflow-y: scroll;max-height: 400px;
} */
.warehouselisting--td {
 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
  /* border: 1px solid #ddd; */
}
.warehouselisting--td:hover{
  background: none;
}
.input-class {
  height: 39px;
 
  border-color: #d5d5d5;
  box-shadow: none;
  padding-left: 10px;
  border: none;
  background: none;
  border-radius: 0px;
  border-bottom: 1px solid #d5d5d5;
}
.inp-siz{
  font-size: 14px;
}

.shipment-template {
  border: none;
  background: #F4F4F4;
  padding: 16px;
  margin: 7px 10px 11px 0px;
}
.shipmen-template input{
    margin-right:10px !important;
}



.warehouse-form .form-select{
  margin:0px !important;
  font-size: 14px;
}
.warehouse-form label {
  display: initial;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  padding-left: 14px !important;
  position: relative;
  top: 0px;
}
.warehouse-form .row {
  margin:-17px 0 19px 0;
}


tr:nth-child(even) {background-color: #f2f2f2 !important;}
table {
  border:none !important;
}
.back-icon{
  cursor: pointer;
  height: 35px;
  width: 35px;
}
.back--para{
  display: flex;
}
.back-p {
  font-weight: 900;
  font-size: 14px;
  position: relative;
  top: 8px;
  left: 10px;
  color: #000;
}
.add-fulfillment-para {
  background-color: #4D5972;
  color: #fff;
  margin-top: 11px;
  padding: 14px;
  font-weight: 900;
  font-size: 14px;
  height: 48px;
}
.input--label{
  font-size: 14px;
  font-weight: 900;
}



.input-container>label {
  position: relative;
  top: 13px;
  left: 16px;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 15px;
  background: #fff;

}

.input-container>input {
  border: none;
  /* background: transparent; */
  outline: none;
  border: 1px solid #00000040 !important;
  height: 40px !important;
  border-radius: 1px !important;
}

.input-container>select {
  border: none;
  /* background: transparent; */
  outline: none;
 border: 1px solid #00000040;
 height:40px !important;
 border-radius: 1px !important;
}
.inp-padding{
  padding: 0px 10px 0px 0px;
}
.shipmentconfig--para {
  font-size: 14px;
  font-weight: 900;
  position: relative;
  top: 12px;
 
}
.tags--ul{
  margin: 0px;
  float: left;
  position: absolute;
  padding: 0;
  position: relative;
  top: 3px;
  left: -13px;
}
.shipment--tags--li{
  list-style: none;
    display: inline-block;
    padding: 14px 24px;
    text-align: center;
    margin: 10px;
  
    height: 48px;
    position: relative;
    top: -8px;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    background: #F4F4F4;
}
.newfulfillment-bulk--upload{
  font-size: 14px;
    border: 1px solid #4D5972;
    background: #fff;
    height: 40px;
    width: 100%;
    margin-left: 13px;
}

.fulfillmnet--submitbtn{
  background: #4D5972;
    font-size: 14px;
    border: 1px solid #4D5972;
    border-radius: 1px;
    font-weight: 500;
    height: 40px;
    width: 100%;
    position: relative;
    left: 13px;
    color:#fff;
  
    top: -10px;
}
.serviceable--pincode--table th{
  background: #A1A1A1 !important;
  border: none;
}
.serviceable--pincode--table th{
  text-transform: capitalize;
}
table.borderless td,table.borderless th{
  border: none !important;
}
/* .serviceable--pincode--table tbody tr:nth-child(odd) {
  background-color: #f2f2f2; 
} */
.serviceable--pincode--table,
.serviceable--pincode--table th,
.serviceable--pincode--table td {
  border: none !important;
  border-style: transparent;
}



.shipmentconfig-div {
  overflow-y: scroll; /* Enable vertical scrollbar */
  max-height: 270px; /* Set maximum height */
  scrollbar-width: 30px; 
  /* -ms-overflow-style: none;  */
}

/* Style the container when hovering over it */
.shipmentconfig-div:hover::-webkit-scrollbar {
  width: 16px; /* Set custom width */


}


.shipmentconfig-div::-webkit-scrollbar-track {
  background-color: #d5d5d5; 
 
}




.shipmentconfig-div::-webkit-scrollbar-thumb {
  background-image: url('../../images/slider.png');
  background-repeat: no-repeat;
  background-size: auto 40px; /* Increase the height of the image */
  background-position: top; /* Set background position to top */
  height: 40px; /* Set height of the scrollbar */
}



.radio-button-container {
  display: flex;
  flex-direction: column;
}

.radio-button-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radio-button-label input[type="radio"] {
  display: none; /* Hide default radio button */
}

.radio-button-label img {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  position: relative;
  right: 20px;
  top: -2px;
}


.radio-button-label input[type="radio"]:checked + img {
}
.shipment--tags--li {
  position: relative;
}

.close-icon {
  position: absolute;
  top: -3px;
  background: #e6e2e2;
  right: 5px;
  color: #000;
  font-weight: bold;
  font-size: 11px;
  cursor: pointer;
  border-radius: 50px;
  width: 15px;
  height: 15px;
  padding: 0px 3px;
}
