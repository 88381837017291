.order--detailspage{
    margin-top:3%;
}
.order-detailspage {
    padding: 20px;
  }
  .order-details.row{
    margin-top:25px;
    /* white-space: nowrap; */
  }
  .order-key {
    font-weight: 800;
    font-size: 14px;
    margin-right: 5px;
    display: block;
    color:#000 !important
  }
  .address--row{
    background: #f4f4f4;
    border-radius: 0px;
    margin: -21px 0px 17px 0px;
  }
  .address-key {
    font-weight: bold;
  }
  .first-address-card{
    padding:0;
  }
  
  .line-items {
    margin-top: 20px;
  }
  
  .line-items table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .line-items table, .line-items th, .line-items td {
    border: 1px solid #ddd;
  }
  
  .line-items th, .line-items td {
    padding: 8px;
    text-align: left;
  }
  
 .line-items th {
    background-color: #f2f2f2;
    white-space: nowrap;
  }
  .card{
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border:none;
  }
  .line-itemsul li{
    list-style: none;
  }
  .order-details .ul--details li {
    width: 33%;
    /* display: block; */
    /* text-align: center; */
    padding: 9px 12px;
    list-style: none;
    margin: 31px 9px 0px 100px;
    border-left: 5px solid #3D4358;
    border-radius: 5px 0px 0px 5px;
}
.order-details ul {
  display: flex;
  padding-top: 10px;
  margin: 10px 48px 24px 0px;
  padding: 0;
}
.order-details .card {
  margin-top: -17px;
  margin-bottom: 21px;
  background: #F4F4F4;
  box-shadow: none;
  border-radius: 0px;
}
table th {
  white-space: nowrap !important;
  background: #4D5972 !important;
  font-weight: 200;
  color: #fff !important;
}
.back-icon{
  cursor: pointer;
}

.order--id {
  font-size: 14px;
  padding-right: 4px;
  position: relative;
  /* top: 8px; */
  left: 5px;
  margin-top: 7px;
  font-weight: 800;
}
.order-grp{
  display:flex;
  margin-top:-19px;
}
.order--details{
  background: #F4F4F4;
    border-radius: 30px 30px 0px 0px;
}
.headr{

    background: #4D5972;
    color: #fff;
    font-weight: 800;
    font-size: 14px;
    border-radius: 1px !important;
    height: 48px;
    line-height: 32px;
}
.orderdetails--bg {
  /* background-color: #F4F4F4; */
  /* margin-top: 29px; */
  position: relative;
  top: 22px;
  border-radius: 30px 30px 0px 0px;
}
li.address-list {
  padding: 3px 0px 0px 0px !important;
}
.orderdetails--bg th,tr{
  font-size: 14px;
  /* font-weight: 800; */
}

.table>:not(caption)>*>* {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 28px;
  line-height: 40px;
}
.check-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 25px;
  line-height: 40px;
  position: relative;
  top: 4px;
}
.order-details--table{
  font-weight: 800 !important;
  font-size: 14px !important;
}
select{
  border: none;
    background: none;
    outline: none;
}
.address-list {
  list-style: none;
  display: block;
  font-weight: 500;
  font-size: 14px;
  /* position: absolute; */
  /* left: 0; */
  font-weight: 900;
  line-height: 19px;
}
.ul--list{
  display: block !important;
  margin: 0 !important;
}
td{
  border:none;
}
.process-btn{
  background: none;
  border:1px solid #000;
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  width:100%;
  margin-top:5px;
}
.ke--value{
  font-weight: 500;
  font-size: 14px;
  color:#000
}
.audit--notes--row{
  margin-top:59px;
}
.order-details td{
border:none;
}
.auditlogs{
  width:100%
}
.auditlog-card{
  background:#fff;
  padding: 0px;
  margin-top:-4px
}