/* Add this CSS to your stylesheets or use a CSS-in-JS solution */

.tabs-container {
    display: flex;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
  }
  
  .tab {
    flex: 1;
    text-align: center;
    padding: 10px;
    display: inline-flex;
    cursor: pointer;
    border: 1px solid #ddd;
    border-bottom: none;
    background-color: #f4f4f4;
    border-top-right-radius: 15px;
  }
  
  .tab:hover {
    background-color: #eaeaea;
  }
  
  .tab.active {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    color: #fff;
    background-color: rgb(0,51,102);
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid #ddd;
  }
  .type--class{
    height: 35px;
    width: 100%;
    margin: 0 !important;
  }
  
  .add-row-btn{
    background: rgb(0,51,102);
    border: none;
    color:#fff;
    border-radius: 5px;
    height: 30px;
    float: right;
    margin-bottom: 10px;
  }
  .one--div{
    display: flex;
  }
  .warehouse--conditions--div{
    margin-top:0px !important;
  }
  .warehouse--conditions--div select{
    margin:0px  !important;
  }
  .one--div h6 {
    padding-left: 10px;
    padding-top: 8px;
}
.inp-group{
    display: flex;
    width: 85px;
}
select.type--class {
    margin-bottom: 12px !important;
}
.th--action{
    text-align: right !important;
}
.delete-btn{
    background:none;
    border:none;
    text-align: right;
}
.prepend{
    font-weight: bold;
    padding-top: 16px !important;
    padding-left: 5px;
    padding-right: 5px;
}
.tab-content input[type="text"]{
    height: 35px;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    width: -webkit-fill-available;
    outline: none;
}
.packaging--type{
    padding-top: 10px;
    padding-left: 10px;
}
.lkl{
  width: 6% !important;
}
.trsh{
  position: relative !important;
    top: 6px !important;
    left: 10px;
    /* right: 17px; */
    cursor: pointer  !important;
}
.hd.hedr--pos{
   position: relative;
    top: 0px;
    left: 10px;
}
table.table.ordb {
  box-shadow: 0px 0px 4px 0px #00000040;
}
.white--space-label{
  white-space: nowrap;
}
.inp-width{
  width:98px;
}
.inp .inp-width {
  width: 114px;
  margin-top: 6px;
}
.create--shipment--btn{
  border:none;
  background: #4D5972;
}
.create--shipment--btn {
  border: none;
  background: #4D5972;
  /* width: 100%; */
  height: 41px;
  float: right;
  color: #fff;
  border: none;
  font-size: 14px;
  position: relative;
  left: -3px;
  border-radius: 0px !important;
  background: rgba(77, 89, 114, 1);
  width: 14%;
}
.shipping--charges table.shipmethod th{
  background: #DEDBDB !important;

}
.kll{
  width:33% !important;
}
.shipping--charges--dropdown{
  background: #F4F4F4;
  border-radius: 1px;
  height: 42px !important;
  padding-left: 6px;
  border: 1px solid #d5d5d5;
  position: relative;
  top: 3px;
}
.shipping--chargelabels{
  
    font-family: 'AvenirLTStd-Book';
    font-size: 14px;
    font-weight: 800;
    /* line-height: 10px; */
    text-align: left;
    
}
.shippingcharge--inp{
  background: #F4F4F4 !important;
  border-radius: 1px !important;
  height: 42px !important;
  border: 1px solid #d5d5d5 !important;
  padding-left: 6px !important;
  position: relative !important;
  top: 3px !important;
  font-size:14px;
}
.shippingcharges--page th{
  font-family: 'AvenirLTStd-Book';
 
  background: #DEDBDB !important;
  color: #000 !important;
}
.shippingcharges--page  tr:nth-child(even) {
  background-color: #F4F4F4 !important;
}
.cl-hd{
  font-size: 14px;
  font-family: 'AvenirLTStd-Book';
  font-weight: 800;
  padding-top: 11px;
}
.addrow-inp{
  background: #F4F4F4;
  border-radius: 1px;
  font-size: 14px;
  height: 41px;
}
.shippingcharges--label{
  font-family: 'AvenirLTStd-Book';
    font-size: 14px;
    font-weight: 800;
    line-height: 10px;
    /* text-align: center; */
}
.addrow-table--div{
  margin:0px 3px 0px 3px
}
.trassh--icon{
  position: relative;
    /* top: 36px;
    right: 17px; */
    cursor: pointer;
}
.sg-charges td{
  background: #fff !important;
}

table .orderbased--discount{
  background: #fff !important;
  border: none !important;
}
table .ordb{
  background: #fff;
  box-shadow: 0px 0px 4px 0px #00000040;

}
.lst{
  width:4%;
}
.shippingcharges--page .add-row-btn {
  background: none;
  color: #000;
  border-radius: 0px;
  height: 40px;
  width: 14.9%;
  font-size:13px;
  float: right;
  border: 1px solid #4D5972;
  margin-bottom: 10px;
}
.unitss{
  border: 1px solid #4D5972;
    margin-left: 24px !important;
    border-radius: 1px;
}
.dimesionbased{
  font-family: 'AvenirLTStd-Book';
  font-size: 14px;
  font-weight: 800;
  padding-left: 8px;
}
tr.sg-charges {
  height: 10px !important;
}
.shipment--creation--inp{
  border: none;
  border-bottom: 1px solid #00000040;
  border-radius: 0px;
  font-size: 14px;
}
.shipment--creation--inplabel{
  font-family: 'AvenirLTStd-Book';
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  text-align: center;
}
.shipmentcharges--footer{
  border:none !important
}
.shipmentcharges--submit{
  background: #4D5972;
    border: none;
    font-size: 14px;
    position: initial !important;
}
.shipment--creation--headerpopup{
  font-weight: 900;
  font-size: 15px;

}
.shippingcharges--page {
  margin-top: 25px;
}