.popup--title {
  font-size: 14px;
  font-weight: 900;
}
.btn-close {
  float: right;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: #000 !important;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity = 20);
  opacity: 1;
}
.shipment--inp {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #d5d5d5;
  outline: none;
  border-radius: 0px;
  font-size: 13px;
}
.shipmentmethod--label {
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
}
.shipmentmethod--footer .upload--btn{
    height: 48px;
    top: 67px;
    width: 100%;
    font-size: 14px;
    color: #fff;
    background: #4D5972;
    border: 1px solid #4D5972;
    /* opacity: 0px; */
    font-weight: 900;
    border-radius: 1px;
    width: 30%;
    position: relative;
    top: -16px;
  }
  .shipmentmethod--footer{
border:none
  }
  .fld--mar{
    margin-top:14px
  }
  .shipmentmethod--footer{
    padding: 0 10px;
  }