.loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
  }
  
  .loader-image {
    max-width: 100%;
    height: auto;
    
  }
  
  .loading-text {
    text-align: center;
    font-weight:700;
    letter-spacing: .3rem;
    padding-top:15px;
  }


  