.confirmbtn{
    background-color: #4D5972;
    color: #fff;
    width: 20%;
    border: none;
    border-radius: 1px;
    font-size: 14px;
}
.confirmtxt{
    font-size: 14px;
    font-family: 'AvenirLTStd-Book';
    font-weight: 800;
}