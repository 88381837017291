.custom-tabs {
 
    border-radius: 5px;
    overflow: hidden;
    margin-top:3%;
  }
  
  .ant-picker-input>input::placeholder {
    color: #000000 !important;
 }
 
 .elliseicon {
  position: absolute;
  right: 76px;
  margin-top: 19px;
  /* top: 14%; */
  height: 19px;
  width: 5px;
  cursor: pointer;
  z-index: 1111;
}