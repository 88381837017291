.first--row{
    margin-top: 30px;
    padding: 10px;
}
.table .table-hover{
    margin-top: 20px !important;
}
td{
    width:20%;
}
.create-store--btn{
    margin-top: 10px;
    background: #003366;
    color: #fff;
    border-radius: 18px;
    border:none;
    box-shadow: none;
    padding:4px 10px;
    text-decoration: none;
}

/* Default styles */
.table.table-hover {
    overflow-x: scroll;
    max-width: 1050px;
}

/* Media query for laptops */
@media only screen and (max-width: 1440px) {
    .table.table-hover {
        /* Adjust properties for laptops */
        overflow-x: auto; /* or any other suitable value */
        max-width: 1090px; /* Ensures it adjusts to the container */
    }
}

@media only screen and (min-width: 1500px) {
    .table.table-hover {
        /* Adjust properties for laptops */
        overflow-x: auto; /* or any other suitable value */
        max-width: 100%; /* Ensures it adjusts to the container */
    }
}