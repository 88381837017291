p.shipmentconfig--headers{
    font-size: 14px;
    font-weight: 800;
    line-height: 10px;
    text-transform: capitalize;
    font-family: initial;
}

.shipment--config--input {
    padding: 0px 13px 0px;
    font-size: 14px;
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
    border-radius: 0px !important;
    background: rgba(244, 244, 244, 1);
    border: rgba(244, 244, 244, 1) !important;
    margin-bottom: 25px;
    height: 45px !important;
}
label.shipmentconfig--headers {
    font-weight: 800;
    text-transform: capitalize;
    font-size: 14px;
    white-space: nowrap;
}
.add-fulfillment-para{
    text-transform: capitalize;
    
}
.shipment--config--input{
    outline: none;
    width: 100%;
    display: block;
    
}