.notes--card{
 background:#fff !important;
 box-shadow: 0px 0px 4px 0px #00000040 !important;
border-radius: 20px !important;
} 
.notes--heading{
    font-weight: 500;
    color: #000;
    font-size: 14px;
}
.notes--input{
    border:1px solid #000;
    height:125px;
    border-radius: 10px;
    font-size: 14px;
    color:#000 !important;
    font-weight: 700;
    width: 100%;
    outline: none;
    margin-top:-10px;
}
.notes--input::-webkit-input-placeholder {
    text-align: center;
    position:absolute;
    top:30px
  }
  .notes-icon{
    position: absolute;
    right: 18px;
    top: 15px;
    cursor: pointer;
  }
  .notes--icons{
    position: absolute;
    right: 25px;
  }
  .tick--icon{
    margin: 0px 13px;
    cursor: pointer;
  }
  .cancel--icon{
   
    cursor: pointer;
  }
  .notes--list{
    border-bottom: 1px dashed #DDE2E6;

    padding: 13px 4px;
    list-style: none;
    
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
    text-align: left;
    

  }
  .ul--notes{
    display: block !important;
    width: 100%;
    max-height: 254px;
    overflow: scroll;
  }
  