/* Add this CSS to your stylesheets or use a CSS-in-JS solution */

.tabs-container {
    display: flex;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
  }
  
  .tab {
    flex: 1;
    text-align: center;
    padding: 10px;
    display: inline-flex;
    cursor: pointer;
    border: 1px solid #ddd;
    border-bottom: none;
    background-color: #f4f4f4;
    border-top-right-radius: 15px;
  }
  
  .tab:hover {
    background-color: #eaeaea;
  }
  
  .tab.active {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    color: #fff;
    background-color: rgb(0,51,102);
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid #ddd;
  }
  .type--class{
    height: 35px;
    width: 100%;
    margin: 0 !important;
  }
  
  .add-row-btn{
    background: rgb(0,51,102);
    border: none;
    color:#fff;
    border-radius: 5px;
    height: 30px;
    float: right;
    margin-bottom: 10px;
  }
  .one--div{
    display: flex;
  }
  .warehouse--conditions--div{
    margin-top:0px !important;
  }
  .warehouse--conditions--div select{
    margin:0px  !important;
  }
  .one--div h6 {
    padding-left: 10px;
    padding-top: 8px;
}
.inp-group{
    display: flex;
    width: 85px;
}
select.type--class {
  margin-bottom: 12px !important;
  font-size: 14px;
}
.th--action{
    text-align: right !important;
}
.delete-btn{
    background:none;
    border:none;
    text-align: right;
}
.prepend{
    font-weight: bold;
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 5px;
}
.tab-content input[type="text"]{
    height: 35px;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    width: -webkit-fill-available;
    outline: none;
}
.packaging--type{
    padding-top: 10px;
    padding-left: 10px;
}
.hedr--pos{
   position: relative;
    top: 4px;
    left: 10px;
}
.white--space-label{
  white-space: nowrap;
}