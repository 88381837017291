/* Login.css */

/* General styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  height: 100vh;
  background: linear-gradient(90deg, #FFF 5.33%, rgba(10, 201, 216, 0.08) 148.59%);
}

.login-containersec {
  height: 100vh;
  margin: 0;
}

.col-md-7.login-input-section {
  background: linear-gradient(to right, #FFFFFF, #bcfaff);
}

.outer-border {
  width: 76px;
  height: 76px;
  margin: auto;
  position: relative;
  top: -20px;
  border: 6px solid #FFFFFF;
  border-radius: 50%;
  background: linear-gradient(to right, #FFFFFF, #7FD7E6);
}

.inner-border {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #0AC9D8;
  background: #FFFFFF;
  box-sizing: border-box;
}

.outer-moving--icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Styling for input fields */
.input-with-icon {
  position: relative;
}

.vl {
  border-left: 1px solid #d7d7d7;
  height: 26px;
  position: absolute;
  top: 8px;
  left: 34px;
}

.input-with-icon input {
  padding-left: 41px;
  font-size: 14px;
  font-weight: 400;
  color: #000 !important;
  box-shadow: none;
}

.icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input-style {
  border: 1px solid #091734;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 40px;
  outline: none;
  width: 100%;
}

.checkbox-forgot {
  padding: 15px 0px;
}

.remember-me-text {
  position: relative;
  left: 4px;
  font-size: 14px;
}

.eye-slash {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.icon--div {
  display: flex;
}

/* Styling for responsive layout */
@media (max-width: 768px) {
  .inner-border {
      height: 15%; 
      width: 15%; 
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .inner-border {
      height: 20%; /* Adjust as needed */
      width: 20%; /* Adjust as needed */
  }
  .image1 {
    height: 45% !important;
    width: 44% !important;
    position: relative;
    top: 73px !important;
    left: 25px;
    display: block;
}
.image2 {
  /* position: absolute; */
  left: 0px;
  top: 160%;
  width: 169px;
  display: block;
  margin-top: 49%;
}
}

@media (min-width: 992px) {
  /* .inner-border {
      height: 25%;
      width: 25%; 
  } */
}

.snowgif-img {
  margin-left: -90px;
  opacity: 0.32 !important;
  position: absolute;
  top: -40px;
  left: 0;
  width: 100%;
  height: 90%;
  z-index: 0;
  pointer-events: none;
}

.login-input-section {
  background: linear-gradient(
    90deg,
    #fff 5.33%,
    rgba(10, 201, 216, 0.08) 148.59%
  );
  position: relative;
  height: 100%;
}

.login--btn {
  background: linear-gradient(260deg, #091330 0%, #0ac9d8 100%);
  width: 100%;
  border: none;
  color: #fff;
  height: 40px;
  border-radius: 10px;
}

.login-inputs {
  position: absolute;
  width: 300px;
  z-index: 15;
  top: 27%;
  left: 50%;
  transform: translateX(-50%);
}

.left {
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 138px solid black;
  border-right: 182px solid transparent;
}
a.forgot-password {
  float: right;
  font-size: 14px;
}
.right {
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 20vh solid black; 
  border-left: 16vw solid transparent; 
  z-index: 1; 
}

.inner-image {
  position: absolute;
  top: 10.1%;
  height: 72.5%;
  z-index:1;
  /* max-height: 354px; */
}

.image2 {
  /* position: absolute; */
  left: 0px;
  top: 160%;
  width: 294px;
  display: block;
  margin-top: 45%;
}
.containerr{
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 24%;
  left: 50%;
  margin: -100px 0 0 1px;
}
.image1 {
  position: relative;
  top: 120px;
  left: 25px;
  display: block;
}

.triangle {
  width: 0px;
  height: 31px;
  border-left: 29px solid transparent;
  border-right: 267px solid transparent;
  border-bottom: 196px solid black;
  position: absolute;
  border-radius: 50%;
  top: 350px;
  left: calc(55% - 20px);
  transform: translateX(-50%);
}

.login-containersec::-webkit-scrollbar {
  display: none !important;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
