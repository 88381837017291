.header--title {
    color: #fff;
    position: relative;
    left: 23%;
    top: 20%;
    font-size: 14px;
    letter-spacing: 3px;
}
.brand--logo{
    height: 31.03px;
    width: 50px;
}
a.navbar-brand {
    margin-left: 35px;
}
.brand--image{
    padding-left:15px;
}
.navbar-expand .navbar-nav .nav-link {
    color: #ffff;
    font-size: 14px;
}
.sidebar ul {
    padding: 0;
    list-style: none;
    font-weight: 800;
    margin: 0;
    font-size: 14px;
    padding-top: 14px;
    text-align: center;
}
  
.fixed-top{
    background: #3D4358!important;
    box-shadow: 0 0 4px 4px rgba(0,0,0,0.08);
    height:70px;
    font-weight: 900;
    font-family: 'AvenirLTStd-Book', Arial, sans-serif;
}
.toggle--headermenu {
    position: absolute;
    top: 136px;
    right: 5px;
    background: #E3FDFF;
    padding: 7px;
    box-shadow: 0px 2px 4px 0px #00000040;

    color: #fff;
    width: 46px;
    height: 38px;
    text-align: center;
    border-radius: 0px 0px 10px 10px;
    cursor: pointer;
    transition: margin-left 0.3s ease; 
}
ul.nested--menu {
    background: #fff;
    width: 18%;
    margin: auto;
    display: block;
    opacity: 1;
    z-index:1111;
    /* position: relative; */
    top: 68px;
}

.toggle-header-menu-inverted {
    position:absolute;
  top:70px;
    right: 5px;
    background: #E3FDFF;
    padding: 7px;
    color: #fff;
    width: 46px;
    
    height: 38px;
    text-align: center;
    border-radius: 0px 0px 10px 10px;
    cursor: pointer;
}
.logout--p{
    margin-right: 71px;
}